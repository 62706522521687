<template>
  <div class="box-faq">
    <v-container fluid justify-start>
      <v-row class="ta-c pa-0">
        <v-col md="12" lg="12">
          <h2 class="font-weight-bold">
            FAQ
          </h2>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="12" md="6" lg="6">
          <div>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel
                v-for="(i, index) in listQuestion"
                :key="index"
              >
                <v-expansion-panel-header class="font-weight-bold fs-16">
                  {{ i.question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  v-html="i.answer"
                  class="pl-6 pr-6 pb-4 fs-16"
                  style="display: block !important; flex:1; flex-flow:column"
                >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="12" md="6" lg="6">
          <v-alert border="top" color="primary">
            สอบถามข้อมูลเพิ่มเติม ติดต่อ ส่วนผลิตกล้าไม้ กรมป่าไม้ โทร.
            (02)-561-4292-3 ต่อ 5551 หรือ 5514
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panel: [0],
      listQuestion: [
        {
          question: "มีกล้าไม้อะไรแจกบ้าง?",
          answer:
            "มีกล้าไม้มงคล 10 ชนิด ได้แก่ กันเกรา ขนุน ตะเคียนทอง ทรงบาดาล ประดู่ป่า มะค่าโมง มะขาม พะยูง ราชพฤกษ์ สัก และกล้าไม้ อื่น ๆ"
        },
        {
          question: "สามารถรับกล้าไม้ “พฤกษามหามงคล” ได้คนละกี่กล้า?",
          answer:
            "ท่านละไม่เกิน 100 กล้า โดยจำกัดสิทธิ์การรับกล้าไม้ท่านละ 1 ครั้ง และเลือกเพียง 1 ช่องทาง (จองออนไลน์ หรือ แบบ Walk In) <br>* หมายเหตุ เงื่อนไขอาจมีการเปลี่ยนแปลง"
        },
        {
          question: "หน่วยงานใดบ้างที่แจกจ่ายกล้าไม้?",
          answer:
            "ท่านสามารถรับกล้าไม้ได้ทั่วประเทศ และตรวจสอบหน่วยงานที่แจกจ่าย ได้ที่<a href='https://happytree.forest.go.th/map'> https://happytree.forest.go.th/map</a>"
        },

        {
          question: "การจอง และขอรับกล้าไม้ มีขั้นตอนอย่างไร?",
          answer:
            "<div class='mb-1'>1. คลิกปุ่ม <a href='https://fp.forest.go.th/rfd_app/rfd_nursery/app/event_tree_search.php' target='_blank' style='display:contents;'><v-btn style='background-color: #ff9f80; padding: 5px 20px 5px 20px; color:white; border-radius:20px;font-weight:bold;'>จองกล้าไม้</v-btn></a> เพื่อลงทะเบียนจองกล้าไม้ </div> 2. นำรหัสการจองไปยื่นรับกล้าไม้ยังหน่วยงานที่จองไว้ ตามวันและเวลาที่กำหนด<br>3. รับกล้าไม้"
        },
        {
          question: "หากไม่ทำการจองกล้าไม้ สามารถรับกล้าไม้ได้อย่างไร?",
          answer:
            "1. ตรวจสอบชนิดกล้าไม้จากหน่วยงานที่มีการแจกจ่ายกล้าไม้<br>2. นำบัตรประจำตัวประชาชนเดินทางไปติดต่อขอรับกล้าไม้ด้วยตนเอง (แบบ Walk In) ที่หน่วยงาน <br>3. รับกล้าไม้"
        },
        {
          question: "ใช้หลักฐานอะไรบ้างในการขอรับกล้าไม้?",
          answer:
            "1. บัตรประจำตัวประชาชน<br>2. หมายเลขการจอง (กรณีจองกล้าไม้ออนไลน์)"
        },
        {
          question: "หากไม่รับกล้าไม้ตามวัน เวลา ที่จองไว้ จะเป็นอย่างไร?",
          answer:
            "การจองของท่านจะถูกยกเลิกโดยอัติโนมัติ ท่านจะต้องทำการจองใหม่อีกครั้ง หรือเดินทางติดต่อ	ขอรับกล้าไม้ได้ด้วยตนเอง (แบบ Walk In) จากหน่วยงานที่แจกจ่ายกล้าไม้ "
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.box-faq {
  min-height: calc(100vh - 105px);
  background-image: url("../assets/images/bg3.jpg");
  background-size: cover;
  background-position: bottom;
}
</style>
